import discussions from './discussions';
import discussionsPreferences from './discussionsPreferences';

export const live = {
  ...discussions,
  ...discussionsPreferences,
};

const api = { ...live };
export default api;

export const replaceAPI = (newApi: Record<string, any>) => {
  Object.keys(newApi).forEach((i) => {
    (api as any)[i] = newApi[i];
  });
};
