import type { InjectionKey } from 'vue';
// eslint-disable-next-line local-rules/no-vuex-store
import { useStore as baseUseStore, type Store } from 'vuex';
import type { State as AssessmentState } from '@/modules/assessment/store/mutations';
import type { State as FrameworkState } from '@/modules/framework/store-deprecated';

export type State = {
  assessment: AssessmentState;
  framework: FrameworkState;
  profile: never; // TODO: this can be removed when we're confident it's not needed anymore
  legacy: {
    countries: {
      all: { name: string; code: string }[];
    };
    [name: string]: any;
  };
  [name: string]: any;
};

export const storeInjectionKey: InjectionKey<Store<State>> = Symbol('vuex-store');

// A partially typed `useStore`
export const useStore = () => baseUseStore(storeInjectionKey);
