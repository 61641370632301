import type { Action } from 'vuex';
import api from '../api';

const setInviteStatus: Action<any, any> = async ({ commit, dispatch, state }, status) => {
  const oldStatus = state.status;
  commit('SET_INVITE_STATUS', status);
  try {
    await dispatch('getInvites', 0);
  } catch (e) {
    logError(e);
    commit('SET_INVITE_STATUS', oldStatus);
  }
};

const setInviteSentBy: Action<any, any> = async ({ commit, dispatch, state }, sentBy) => {
  const oldSentBy = state.sentBy;
  commit('SET_INVITE_SENT_BY', sentBy);
  try {
    await dispatch('getInvites', 0);
  } catch (e) {
    logError(e);
    commit('SET_INVITE_SENT_BY', oldSentBy);
  }
};

const setInviteName: Action<any, any> = async ({ commit, dispatch, state }, name) => {
  const oldName = state.name;
  commit('SET_INVITE_NAME', name);
  try {
    await dispatch('getInvites', 0);
  } catch (e) {
    logError(e);
    commit('SET_INVITE_NAME', oldName);
  }
};

const setInviteSort: Action<any, any> = async (
  { commit, dispatch, state },
  { sort, sortDirection },
) => {
  const oldSort = state.sort;
  const oldSortDirection = state.sortDirection;
  commit('SET_INVITE_SORT', { sort, sortDirection });
  try {
    await dispatch('getInvites', 0);
  } catch (e) {
    logError(e);
    commit('SET_INVITE_SORT', { sort: oldSort, sortDirection: oldSortDirection });
  }
};

const getInvites: Action<any, any> = async ({ state, commit }, offset) => {
  commit('START_LOADING');
  commit('SET_OFFSET', offset);
  const res = await api.getInvites(state.status, {
    limit: state.meta.limit,
    offset,
    name: state.name,
    sort: state.sort,
    sentBy: state.sentBy,
    sortDirection: state.sortDirection,
  });
  commit('SET_INVITES', res.data);
};

const getInvitesCounts: Action<any, any> = async ({ commit }) => {
  const res = await api.getInvitesCounts();
  commit('SET_INVITES_COUNTS', res.data);
};

const resendInvite: Action<any, any> = async ({ commit }, inviteID) => {
  const res = await api.resendInvite(inviteID);
  commit('RESEND_INVITE', res.data);
};

const cancelInvite: Action<any, any> = async ({ commit }, inviteID) => {
  await api.cancelInvite(inviteID);
  commit('CANCEL_INVITE', inviteID);
};

const clearInviteStore: Action<any, any> = ({ commit }) => {
  commit('CLEAR_STATE');
};

export const actions = {
  getInvites,
  setInviteStatus,
  setInviteSentBy,
  setInviteName,
  setInviteSort,
  clearInviteStore,
  resendInvite,
  cancelInvite,
  getInvitesCounts,
};
