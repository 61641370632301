import manage from './manage';
import supplier from './supplier';

export const live = {
  ...manage,
  ...supplier,
};

const api = { ...live };
export default api;

export const replaceAPI = (newApi: Record<string, any>) => {
  Object.keys(newApi).forEach((i) => {
    (api as any)[i] = newApi[i];
  });
};
