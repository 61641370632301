import { filesize } from 'humanize-plus';
import type { FileModel } from '@/types/models/file';
import type { FileType } from './types';

// For browsers that do not support the 'accept' input attribute (https://caniuse.com/#feat=input-file-accept)
const validateExtension = (filename: string, validExtensions: string[]) => {
  // if no extensions given then all files are permitted
  if (!validExtensions || validExtensions.length === 0) return true;

  validExtensions = validExtensions.map((ext) => ext.toLowerCase());
  let extension = filename.split('.').pop() as string;

  // Treat extension-less files as having an extension equal to file name
  // e.g. Dockerfile's extension is Dockerfile.
  if (filename.includes('.')) extension = `.${extension}`;

  return validExtensions.includes(extension.toLowerCase());
};

// getValidFiles gets only the Web API File objects that match the valid extensions,
// and have a name different from the existing files.
// Only the first valid File is kept if the `multiple` option is false.
export const getValidFiles = (
  webApiFileList: File[] | FileList,
  currentFilenames: string[],
  validExtensions: string[],
  multiple = true,
): FileType[] => {
  if (!webApiFileList) return [];

  // Convert Web API FileList to an array of our own file structure.
  // The original File is stored inside `fileObject` for later e.g. uploading, content type detection, hashing, etc.
  // This also helps prevent us adding File objects into the store, which seem to hinder its reactivity(?).
  let files = Array.from(webApiFileList).map((fileObject) => ({
    filename: fileObject.name,
    contentType: fileObject.type,
    size: fileObject.size,
    fileObject,
  }));

  // ignore files with invalid extensions
  files = files.filter((file) => validateExtension(file.filename, validExtensions));

  // ignore files with same name as any existing file
  files = files.filter((file) => !currentFilenames.includes(file.filename));

  if (files.length === 0) return [];
  return multiple ? files : [files[0]!];
};

// formatFileSize converts a number of bytes into a friendly size string such as "5 MB".
export const formatFileSize = (sizeInBytes: number) => filesize(sizeInBytes);

// loadFileContent takes a Web API File object and returns its content as a Uint8Array.
export const loadFileContent = (file: File) =>
  new Promise<Uint8Array>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(new Uint8Array(reader.result as ArrayBuffer));
    reader.onerror = () => reject(reader.result);
    reader.readAsArrayBuffer(file);
  });

// hashFile returns a SHA256 hash of some file content.
// `fileContent` should be a Uint8Array, as outputted by loadFileContent.
// Source: https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest#converting_a_digest_to_a_hex_string
// Also helpful: https://luzfcb.github.io/FileAPI-WebCryptoAPI-api-sample/
export const hashFile = async (fileContent: Uint8Array) => {
  const hashBuffer = await crypto.subtle.digest('SHA-256', fileContent);
  return Array.from(new Uint8Array(hashBuffer));
};

export const hashFileBase64Encoded = async (fileContent: Uint8Array) => {
  const hashArray = await hashFile(fileContent);
  const hashString = String.fromCharCode(...hashArray); // Convert bytes to string
  const base64Hash = btoa(hashString); // Encode string to base64
  return base64Hash;
};

// getFileStatus groups together different upload status booleans (loading/failed)
// with different scan statuses (scanning, approved, rejected), since they are all mutually exclusive.
//
// All these states are grouped into the following states for easy use by components:
// - uploading = file is being uploaded to S3.
// - upload_failed = failed to upload file to S3.
// - scanning = file is uploaded and being scanned.
// - rejected = scan rejected the file e.g. it has a virus or invalid content
// - ready = file was scanned, approved, and is ready for download
//
// Note: We are intentionally vague about file status,
// because the user doesn't need to know the details of why it's ready or was rejected,
// they only need to know that they can or can't download a file,
// with only hints of what may have gone wrong to help us debug.
export const getFileStatus = (file: FileModel) => {
  if (file.uploading) return 'uploading';
  if (file.uploadFailed) return 'upload_failed';

  const scanStatus = file.latestScan?.status;
  if (scanStatus === 'approved') return 'ready';
  if (scanStatus === 'rejected') return 'rejected';
  return 'scanning';
};
