const initialState = () => ({
  discussionPreviews: [],
  discussions: {
    byID: {}, // Store of actual discussion data
    allIDs: [],
  },
  comments: {
    byID: {},
    allIDs: [],
  },
  discussionLists: {
    // collection of lists that map other ids to discussion ids. Not data is stored
    byAssociatedID: {}, // Map of associated id to arrays of discussion ids for their orgs, stores not data. References discussion by ID
    byClientID: {}, // Map of org ids to arrays of discussion ids for their orgs, stores not data. References discussion by ID
    bySupplierID: {}, // Map of org ids to arrays of discussion ids for their orgs, stores not data. References discussion by ID
    byOrgID: {}, // Map of org ids to arrays of discussion ids for their orgs, stores not data. References discussion by ID
  },
  users: {},
  orgs: {},
  filters: {
    discussionStatus: 'active',
  },
});

export const CommentStatus = {
  SEEN: 'seen',
  VISIBLE: 'visible',
};

export const state = initialState();

const helperAdd = (list: any, parentId: string, discussionId: string) => {
  if (list != null && list[parentId] == null) {
    list[parentId] = [discussionId];
  }
  if (list[parentId].indexOf(discussionId) === -1) {
    list[parentId] = [...list[parentId], discussionId];
  }
  return list;
};

const addDiscussionToStore = (state: any, discussion: any) => {
  const { associatedID, supplierID, clientID, id } = discussion;

  state.discussions.byID[id] = { ...state.discussions.byID[id], ...discussion };

  // If discussionID is not already in allIDs list add it
  if (state.discussions.allIDs.indexOf(id) === -1) {
    state.discussions.allIDs.push(id);
  }

  state.discussionLists.byAssociatedID = helperAdd(
    state.discussionLists.byAssociatedID,
    associatedID,
    id,
  );

  state.discussionLists.bySupplierID = helperAdd(
    state.discussionLists.bySupplierID,
    supplierID,
    id,
  );

  state.discussionLists.byClientID = helperAdd(state.discussionLists.byClientID, clientID, id);

  state.discussionLists.byOrgID = helperAdd(state.discussionLists.byOrgID, supplierID, id);
  state.discussionLists.byOrgID = helperAdd(state.discussionLists.byOrgID, clientID, id);

  discussion.comments?.forEach((comment: any) => {
    state.comments.byID[comment.id] = comment;
    if (state.comments.allIDs.indexOf(comment.id) === -1) {
      state.comments.allIDs.push(comment.id);
    }
  });
};

export const mutations = {
  SET_DISCUSSION_PREVIEWS(state: any, data: any) {
    state.discussionPreviews = data;
  },
  ADD_DISCUSSION_PREVIEWS(state: any, data: any) {
    data.forEach((dp: any) => {
      const ind = state.discussionPreviews.findIndex((item: any) => item.id === dp.id);
      if (ind > -1) {
        state.discussionPreviews.splice(ind, 1, dp);
      } else {
        state.discussionPreviews.push(dp);
      }
    });
  },
  SET_DISCUSSIONS(state: any, data: any) {
    data.discussions.forEach((discussion: any) => {
      addDiscussionToStore(state, discussion);
    });
  },
  SET_USERS(state: any, data: any) {
    state.users = {
      ...state.users,
      ...data?.users,
    };
  },
  SET_ORGS(state: any, data: any) {
    state.orgs = {
      ...state.orgs,
      ...data?.orgs,
    };
  },
  ADD_COMMENT(state: any, data: any) {
    state.users = {
      ...state.users,
      ...data.users,
    };
    state.orgs = {
      ...state.orgs,
      ...data.orgs,
    };
    state.discussions.byID[data.comment.discussionID].comments.push(data.comment);

    state.comments.byID[data.comment.id] = data.comment;

    if (state.comments.allIDs.indexOf(data.comment.id) === -1) {
      state.comments.allIDs.push(data.comment.id);
    }
    state.discussions.byID[data.comment.discussionID].archivedAt = null;

    // If we are using discussionPreviews update them
    if (state.discussionPreviews.length > 0) {
      const previewIndex = state.discussionPreviews.findIndex(
        (x: any) => x.id === data.comment.discussionID,
      );
      if (previewIndex !== -1) {
        // update last comment
        state.discussionPreviews[previewIndex].lastMessage = { ...data.comment };
        // also push that discussion preview to the top if its not already
        if (previewIndex !== 0) {
          const preview = { ...state.discussionPreviews[previewIndex] };
          // do the unshift and splice on a clone so that we don't have multiple
          // state updates
          const updatedPreviews = state.discussionPreviews.slice(0);
          updatedPreviews.splice(previewIndex, 1);
          updatedPreviews.unshift(preview);
          state.discussionPreviews = [...updatedPreviews];
        }
      }
    }
  },
  SET_COMMENTS_AS_SEEN(state: any, data: any) {
    if (state.discussions.byID[data.discussionId] == null) {
      return;
    }

    for (const commentId of data.commentIds) {
      const commInd = state.discussions.byID[data.discussionId].comments.findIndex(
        (comment: any) => commentId === comment.id,
      );

      state.discussions.byID[data.discussionId].comments[commInd].status = CommentStatus.SEEN;
    }
  },
  SET_COMMENT_AS_VISIBLE(state: any, data: any) {
    if (state.discussions.byID[data.discussionId] == null) {
      return;
    }

    // if comment already has a status then
    // we leave it alone
    const commInd = state.discussions.byID[data.discussionId].comments.findIndex(
      (comment: any) => data.commentId === comment.id && !comment.status,
    );

    if (commInd < 0) {
      return;
    }

    state.discussions.byID[data.discussionId].comments[commInd].status = CommentStatus.VISIBLE;
  },
  START_DISCUSSION(state: any, data: any) {
    state.users = {
      ...state.users,
      ...data.users,
    };
    state.orgs = {
      ...state.orgs,
      ...data.orgs,
    };

    state.discussionPreviews.unshift({
      ...data.discussion,
      comments: null,
      lastMessage: { ...data.discussion.comments[0] },
    });

    addDiscussionToStore(state, data.discussion);
  },
  CLEAR_STATE(state: any) {
    Object.assign(state, initialState());
  },
  ARCHIVE_DISCUSSION(state: any, data: any) {
    // remove discussion preview
    const i = state.discussionPreviews.findIndex((x: any) => x.id === data.discussionID);
    state.discussionPreviews.splice(i, 1);

    state.discussions.byID[data.discussionID].archivedAt = data.archivedAt;
  },
  UNARCHIVE_DISCUSSION(state: any, data: any) {
    // remove discussion preview
    const i = state.discussionPreviews.findIndex((x: any) => x.id === data.discussionID);
    state.discussionPreviews.splice(i, 1);

    state.discussions.byID[data.discussionID].archivedAt = null;
  },
  // Filters - deprecated will remove once Discussion Inbox is live
  SET_DISCUSSION_FILTERS(state: any, filters: any) {
    state.filters = { ...state.filters, ...filters };
  },
};
