import { Amplify } from 'aws-amplify';
import {
  getCurrentUser,
  signUp,
  confirmSignUp,
  resendSignUpCode,
  resetPassword,
  confirmResetPassword,
  sendUserAttributeVerificationCode,
  confirmUserAttribute,
  confirmSignIn,
  signOut,
  updatePassword,
  signIn,
  updateMFAPreference,
  setUpTOTP,
  verifyTOTPSetup,
  fetchAuthSession,
  fetchMFAPreference,
  fetchUserAttributes,
} from 'aws-amplify/auth';
import type {
  AuthUser,
  ConfirmSignInOutput,
  ConfirmSignUpOutput,
  FetchMFAPreferenceOutput,
  FetchUserAttributesOutput,
  ResendSignUpCodeOutput,
  ResetPasswordOutput,
  SendUserAttributeVerificationCodeOutput,
  SignInOutput,
  SignUpOutput,
} from 'aws-amplify/auth';
import type { AuthTOTPSetupDetails } from 'node_modules/@aws-amplify/auth/dist/esm/types';
import cognitoConfig from '@/config/cognito';

type SignUpInputParameters = {
  user_id: string;
  email: string;
  password: string;
  given_name: string;
  family_name: string;
  phone_number: string;
  organisation_id: string;
  invite: string;
  marketing: boolean;
};

class CognitoAuth {
  constructor(config: Parameters<(typeof Amplify)['configure']>[0] = {}) {
    Amplify.configure(config);
  }

  getCurrentUser(): Promise<AuthUser> {
    return getCurrentUser();
  }

  fetchUserAttributes(): Promise<FetchUserAttributesOutput> {
    return fetchUserAttributes();
  }

  /* eslint-disable camelcase */
  signUp({
    user_id,
    email,
    password,
    given_name,
    family_name,
    phone_number,
    organisation_id,
    invite,
    marketing,
  }: SignUpInputParameters): Promise<SignUpOutput> {
    const lowerCaseEmail = email.toLowerCase();

    return signUp({
      username: lowerCaseEmail,
      password,
      options: {
        userAttributes: {
          email: lowerCaseEmail,
          phone_number,
          given_name,
          family_name,
          'custom:organisation': String(organisation_id),
          'custom:user_id': String(user_id),
          'custom:mfa_init': '0',
          'custom:marketing': marketing ? '1' : '0',
          'custom:invite': invite,
        },
      },
    });
  }
  /* eslint-enable camelcase */

  confirmSignup(username: string, code: string): Promise<ConfirmSignUpOutput> {
    const lowerCaseUsername = username.toLowerCase();
    return confirmSignUp({ username: lowerCaseUsername, confirmationCode: code });
  }

  resendCode(username: string): Promise<ResendSignUpCodeOutput> {
    const lowerCaseUsername = username.toLowerCase();
    return resendSignUpCode({ username: lowerCaseUsername });
  }

  forgotPassword(username: string): Promise<ResetPasswordOutput> {
    const lowerCaseUsername = username.toLowerCase();
    return resetPassword({ username: lowerCaseUsername });
  }

  confirmPassword(username: string, code: string, password: string) {
    const lowerCaseUsername = username.toLowerCase();
    return confirmResetPassword({
      username: lowerCaseUsername,
      newPassword: password,
      confirmationCode: code,
    });
  }

  resendValidationCode(
    attr: 'email' | 'phone_number',
  ): Promise<SendUserAttributeVerificationCodeOutput> {
    return sendUserAttributeVerificationCode({ userAttributeKey: attr });
  }

  confirmAttribute(attr: 'email' | 'phone_number', code: string) {
    return confirmUserAttribute({ userAttributeKey: attr, confirmationCode: code });
  }

  completeNewPassword(password: string, userAttributes?: any): Promise<ConfirmSignInOutput> {
    return confirmSignIn({ challengeResponse: password, options: { userAttributes } });
  }

  changePassword(oldPassword: string, newPassword: string) {
    return updatePassword({ oldPassword, newPassword });
  }

  signin(username: string, password: string): Promise<SignInOutput> {
    const lowerCaseUsername = username.toLowerCase();
    return signIn({ username: lowerCaseUsername, password });
  }

  confirmSignin(code: string): Promise<ConfirmSignInOutput> {
    return confirmSignIn({ challengeResponse: code });
  }

  fetchMFAPreference(): Promise<FetchMFAPreferenceOutput> {
    return fetchMFAPreference();
  }

  setPreferredMFAType(
    mfaType:
      | 'SMS_MFA'
      | 'SOFTWARE_TOKEN_MFA'
      | 'CONFIRM_SIGN_IN_WITH_SMS_CODE'
      | 'CONFIRM_SIGN_IN_WITH_TOTP_CODE',
  ) {
    switch (mfaType) {
      case 'SMS_MFA':
      case 'CONFIRM_SIGN_IN_WITH_SMS_CODE':
        return updateMFAPreference({ sms: 'ENABLED', totp: 'DISABLED' });
      case 'SOFTWARE_TOKEN_MFA':
      case 'CONFIRM_SIGN_IN_WITH_TOTP_CODE':
        return updateMFAPreference({ totp: 'ENABLED', sms: 'DISABLED' });
      default:
        throw new Error('Invalid MFA type');
    }
  }

  setupTOTP(): Promise<AuthTOTPSetupDetails> {
    return setUpTOTP();
  }

  async confirmTOTP(code: string) {
    await verifyTOTPSetup({ code });
    return updateMFAPreference({ totp: 'ENABLED', sms: 'DISABLED' });
  }

  logout() {
    signOut({ global: true });
  }

  // Resolves the current token based on a user session.
  async getToken() {
    const { tokens: session } = await fetchAuthSession();
    window.session = session;
    if (session?.accessToken) return session.accessToken.toString();
    throw new Error('No valid session');
  }
}

export default new CognitoAuth(cognitoConfig);
