import { request } from '@/api/request';

const fetchDiscussionsPreferences = () => request('GET', `/discussions/preferences`);

const updateDiscussionsPreferences = (seenEnabled: boolean) =>
  request('PATCH', `/discussions/preferences`, {
    seenEnabled,
  });

export default {
  updateDiscussionsPreferences,
  fetchDiscussionsPreferences,
};
