import { request } from '@/api/request';
import { isAuthenticatedRef, isAuthenticatedByRLRef } from '@/modules/auth/auth';
import type { User, MFAPreference } from '@/types/models/user';

const fetchCurrentUser = () => request<User>('GET', '/user');

// This is NOT the permissions enum as strings can contain wildcards
// Endpoint return type has changed to be object with permissions and accessibleSuppliers not just string[]
export type UserPermissionsResponse =
  | string[]
  | { permissions: string[]; accessibleSuppliers: string[] };
const fetchCurrentUserPermissions = () =>
  request<UserPermissionsResponse>('GET', '/user/permissions');

export type UpdateUserVariables = Partial<
  Pick<
    User,
    | 'email'
    | 'firstName'
    | 'lastName'
    | 'phone'
    | 'language'
    | 'team'
    | 'jobTitle'
    | 'marketingEmails'
  >
>;
const updateUser = (user: UpdateUserVariables) => request<User>('PATCH', '/user', user);

const setUserMFAPreference = (mfaPreference: MFAPreference) =>
  request('PUT', '/user/mfapreference', { mfaPreference });

const getUserMFAPreference = () =>
  request<{ mfaPreference: MFAPreference }>('GET', '/user/mfapreference');

const verifySoftwareToken = (softwareToken: string) =>
  request('POST', '/auth/verifySoftwareToken', { softwareToken });

const setNotificationPreference = (preference: any) =>
  request('PUT', '/notifications/preference', { preference });

const getNotificationPreference = () => request('GET', '/notifications/preference');

const api = {
  fetchCurrentUser,
  fetchCurrentUserPermissions,
  updateUser,
  setUserMFAPreference,
  getUserMFAPreference,
  setNotificationPreference,
  getNotificationPreference,
  verifySoftwareToken,
};
export default api;

export const replaceAPI = (newApi: Record<string, any>) => {
  isAuthenticatedRef.value = true;
  isAuthenticatedByRLRef.value = true;
  Object.keys(newApi).forEach((i) => {
    (api as any)[i] = newApi[i];
  });
};
