import { request } from '@/api/request';
import type { Discussion } from '@/types/models/discussion';

export type AssociatedDiscussionsResponse = { discussions: Discussion[] };

// TODO: we will hopefully refactor endpoints and
// get rid of the /inbox postfix in the urls in the future
const getDiscussionPreviews = ({
  role,
  participationType,
  archived,
  orgFilter,
  page,
  limit,
}: any) =>
  request('POST', '/discussions/inbox', {
    role,
    participationType,
    archived,
    orgFilter,
    page,
    limit,
  });

const getDiscussion = (id: string) => request('GET', `/discussions/${id}/inbox`);

const startDiscussion = (
  associatedID: string,
  associatedType: any,
  message: any,
  clientID: string,
  supplierID: string,
  attachments: any,
) =>
  request('POST', `/discussion`, {
    associatedID,
    associatedType,
    message,
    clientID,
    supplierID,
    attachments,
  });

const replyDiscussion = (discussionID: string, message: any, attachments: any) =>
  request('POST', `/discussion/${discussionID}`, {
    message,
    attachments,
  });

const getDiscussionsByAssociatedID = (associatedID: string) =>
  request('GET', `/discussions/associated/${associatedID}`);

const archiveDiscussion = (discussionID: string) =>
  request('POST', `/discussion/${discussionID}/archive`);

const unarchiveDiscussion = (discussionID: string) =>
  request('POST', `/discussion/${discussionID}/unarchive`);

const markCommentsAsSeen = (discussionId: string, commentIds: string[]) =>
  request('POST', `/discussions/${discussionId}/markCommentsAsSeen`, { commentIds });

export default {
  getDiscussionPreviews,
  getDiscussion,
  getDiscussionsByAssociatedID,
  startDiscussion,
  replyDiscussion,
  archiveDiscussion,
  unarchiveDiscussion,
  markCommentsAsSeen,
};
