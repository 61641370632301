export default {
  en: {
    title: 'Suppliers',
    pageTitle: 'Suppliers - {0}',
    pageHeader: 'Suppliers',
    entityName: 'Supplier',
    dataTableColumns: {
      supplier: 'Supplier Name',
      compliance: 'Compliance',
      approvalStatus: 'Approval Status',
      criticality: 'Criticality',
      confidentiality: 'Confidentiality',
      holdsPII: 'PII',
      labels: 'Labels',
      assignedUsers: 'Assigned',
      policiesApplied: 'Policies Applied',
      dateAdded: 'Date Added',
      companyNumber: 'Company Number',
      country: 'Country',
      managed: 'RL Managed',
      assessmentProgress: 'Assessment Progress',
      lastUpdatedAt: 'Last Answer Updated',
      sector: 'Sector',
      website: 'Website',
      exemptionsApplied: 'Exemptions Applied',
      nonComplianceApplied: 'Non-Compliance Applied',
      remediationRequests: 'Remediation Requests',
      remediationResponses: 'Remediation Responses',
      evidence: 'Evidence',
      activeDiscussions: 'Active Discussions',
      archivedDiscussions: 'Archived Discussions',
      invitedAt: 'Date Invited',
      invitedBy: 'Invite Sender',
      inviteEmail: 'Invite Email',
      approvalExpiresAt: 'Approval Expiry',
      reassessmentNextDueAt: 'Next Re-Assessment Due',
      latestAssessmentProgressPercentage: 'Assessment Progress',
      numRisks: 'Open Risks',
      lastAssessmentDate: 'Last Assessment',
      federatedMutualConnections: 'Federated Shared Connections',
      connectionProfileTypeResolved: 'Profile Type',
      connectionProfileTypeUnclaimed: 'Unclaimed',
      connectionProfileTypeBasic: 'Basic Access',
      connectionProfileTypeFull: 'Full Access',
      connectionProfileTypeManaged: 'RL Managed',
      connectionStatus: 'Connection Status',
      approvalReviewers: 'Reviewers',
    },
    dataTableColumnsTooltips: {
      lastAssessmentDateOverdue: 'Supplier Re-Assessment Overdue',
      lastAssessmentDateIncomplete: 'Supplier Assessment Incomplete',
    },
    dataTable: {
      managedLabel: 'RL Managed',
      managedDescription: 'This profile has been created and is managed by Risk Ledger.',
      incomplete: 'Incomplete',
      filterResultsSuppliers: '{count} Suppliers | {count} Supplier | {count} Suppliers',
      filterResultsOutOf: '{0} out of {1}',
      deletedUser: 'Deleted User',
      communityPeers: '{n} Peer | {n} Peers',
      inviteSend: 'Invite Sent',
      sendRequest: 'Send Request',
      sendInvite: 'Send Invite',
      sendConnectionRequest: 'Request Access',
      notInvited: 'Not Invited',
      notConnected: 'Not Connected',
      connectionStatusAccepted: 'Connected',
      connectionStatusPending: 'Pending',
      connectionStatusRejected: 'Rejected',
      connectionStatusRemoved: 'Removed',
      CONNECTION_STATUS_REQUEST_PENDING: 'Pending',
      CONNECTION_STATUS_CONNECTED: 'Connected',
      CONNECTION_STATUS_NOT_CONNECTED: 'Not Connected',
      CONNECTION_STATUS_REQUEST_REJECTED: 'Rejected',
      CONNECTION_STATUS_REMOVED: 'Removed',
      CONNECTION_STATUS_INVITED: 'Invited',
      CONNECTION_STATUS_NOT_INVITED: 'Not Invited',
    },
    filterAttributes: {
      searchPlaceholder: 'Search suppliers...',
      name: 'Name',
      companyNumber: 'Company Number',
      website: 'Website',
      country: 'Country',
      lastActiveAt: 'Last Answer Updated',
      managed: 'RL Managed',
      managedTrue: 'is Managed',
      managedFalse: 'is not Managed',
      percentageCompliance: 'Compliance',
      criticality: 'Criticality',
      confidentiality: 'Confidentiality',
      holdsPII: 'Holds PII',
      approvalStatus: 'Approval Status',
      assignedToMeForApprovalReview: 'Reviews Assigned to Me',
      assignedToMeForApprovalReviewTrue: 'is assigned to Me for reviews',
      assignedToMeForApprovalReviewFalse: 'is not assigned to Me for reviews',
      approvalReviewers: 'Reviewers',
      approved: 'Approved',
      rejected: 'Rejected',
      removed: 'Removed',
      pending_biz: 'Pending business',
      approvedExpiry: 'Approval Expired',
      inreview: 'In Review',
      unapproved: 'Unapproved',
      approvalModifiedAt: 'Approval Last Modified',
      approvalExpiresAt: 'Approval Expiry',
      numPoliciesApplied: 'Number of Policies Applied',
      createdAt: 'Date Added',
      labels: 'Labels',
      sectorID: 'Sector',
      assignedUsers: 'Assigned Users',
      numActiveDiscussions: 'Number of Active Discussions',
      numArchivedDiscussions: 'Number of Archived Discussions',
      numExemptionsApplied: 'Number of Exemptions Applied',
      numNonComplianceApplied: 'Number of Non Compliances Applied',
      numRemediationRequests: 'Number of Remediation Requests',
      numRemediationResponses: 'Number of Remediation Responses',
      numEvidence: 'Number of Evidence',
      assignedToMe: 'Assigned to Me',
      assignedToMeTrue: 'is Assigned to Me',
      assignedToMeFalse: 'is not Assigned to Me',
      invitedAt: 'Date Invited',
      invitedBy: 'Invite Sender',
      inviteEmail: 'Invite Email',
      reassessmentNextDueAt: 'Next Re-Assessment Due',
      numRisks: 'Open Risks',
      lastAssessmentDate: 'Last Assessment Date',
      assessmentProgress: 'Assessment Progress',
      assessmentComplete: 'Assessment Complete',
      assessmentCompleteTrue: 'True',
      assessmentCompleteFalse: 'False',
      reassessmentOverdue: 'Reassessment Overdue',
      reassessmentOverdueTrue: 'True',
      reassessmentOverdueFalse: 'False',
      federatedMutualConnections: 'Federated Shared Connections',
      profileTypeResolved: 'Supplier',
      profileTypeResolvedClaimed: 'Not unclaimed',
      profileTypeResolvedUnclaimed: 'Unclaimed',
      connectionProfileTypeResolved: 'Profile Type',
      connectionProfileTypeUnclaimed: 'Unclaimed',
      connectionProfileTypeBasic: 'Basic Access',
      connectionProfileTypeFull: 'Full Access',
      connectionProfileTypeManaged: 'RL Managed',
      connected: 'Connected',
      notConnected: 'Not Connected',
      pending: 'Pending',
      inviteSent: 'Invite Sent',
      notInvited: 'Not Invited',
    },
    saveModal: {
      title: 'Save a new view',
      save: 'Save View',
      saveNew: 'Save as new view',
      saveExisting: 'Update existing view',
      updateError: 'There was an error updating the view',
      cancel: 'Cancel',
      description:
        'Give your view a name below. Once saved, it will appear in the sidebar for you and your colleagues.',
      placeholder: 'Name of view...',
    },
    editName: {
      title: `Rename view '{name}'`,
      description: 'Give your view a new name below.',
      placeholder: 'Name of view...',
      cancel: 'Cancel',
      save: 'Save View',
      renameError: 'There was an error renaming the view',
    },
    deleteModal: {
      buttonAlt: 'Delete view',
      title: `Delete view '{0}'?`,
      description: `Please confirm if you would like to remove the '{0}' view. It will no longer appear in the sidebar for you and your colleagues.`,
      delete: 'Delete View',
      cancel: 'Cancel',
      failed: 'Deleting the view failed.',
    },
    placeholders: {
      emptyTitle: 'Suppliers',
      emptyDescription: 'No connections could be found',
      errorTitle: 'Suppliers',
      errorDescription: 'Failed fetching connections',
    },
    export: 'Export',
    exportError: 'There was an error attempting to export the current view',
  },
};
