import axios from 'axios';
import type { InternalAxiosRequestConfig } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import isNil from 'lodash/isNil';
import config from '@/config/api';
import { getToken, isAuthenticated, logout } from '@/modules/auth/auth';
import configKeys from '@/config/widgets';
import useFeatureFlags from '@/composables/feature-flags';
import { RiskledgerSessionIdHeader } from './headerKeys';

const instance = axios.create({
  baseURL: config.base_url,
});

instance.interceptors.request.use(
  async (config): Promise<InternalAxiosRequestConfig> => {
    if (config.headers) {
      const featureFlags = useFeatureFlags();
      const authed = await isAuthenticated();
      if (authed) {
        // If authenticated then add token to request
        try {
          // Attempt to get token (has auto refresh). If it fails then session is invalid.
          const token = await getToken();
          config.headers.Authorization = `Bearer ${token}`;
        } catch (err) {
          // If we can't get a token, we're not logged in, so log out
          const path = await logout(false);
          window.location.href = path;
          return Promise.reject(err);
        }
      }

      const sessionID = sessionStorage.getItem(RiskledgerSessionIdHeader);
      if (sessionID) {
        config.headers[RiskledgerSessionIdHeader] = sessionID;
      }

      config.headers['Riskledger-Request-Id'] = uuidv4();
      config.headers['Riskledger-Client-Build'] = configKeys.buildSHA;
      config.headers['Riskledger-Flag-Overrides'] = featureFlags.overridesForHeader.join(',');
    }

    return config;
  },
  (error) => Promise.reject(error),
);

instance.interceptors.response.use(
  (response) => {
    const sessionID = response.headers[RiskledgerSessionIdHeader];
    if (sessionID) {
      sessionStorage.setItem(RiskledgerSessionIdHeader, sessionID);
    }
    return response;
  },
  async (error) => {
    const response = error.response;

    if (isNil(response)) {
      return Promise.reject(error);
    }

    // eslint-disable-next-line local-rules/track-event-name-format
    trackEvent('requestFailed', {
      frontendRequestID: response.config?.headers?.['Riskledger-Request-Id'],
    });

    if (response.status === 401) {
      const isSSOEnforcedError = response.data.trim() === 'ssoEnforced';
      if (isSSOEnforcedError) {
        // Attempt to log the user out so the current credentials can be removed
        // to force them to reauthenticate due to the 401 response
        const path = await logout(true);
        // no global store flush, so we need to reload to clear store
        // which means using vue-router to push is currently not ideal
        window.location.href = path;
      }
      // If it's not an sso enforcement error, we need to ensure we have a valid session.
      // Retrieve token (auto-refreshes), if it fails then log out.
      getToken().catch(async () => {
        // Attempt to log the user out so the current credentials can be removed
        // to force them to reauthenticate due to the 401 response
        const path = await logout(false);
        // no global store flush, so we need to reload to clear store
        // which means using vue-router to push is currently not ideal
        window.location.href = path;
      });
    } else if (response.status === 503) {
      const url = new URL(window.location.href);
      url.pathname = '/down-for-maintenance';
      url.search = new URLSearchParams({ redirect: window.location.pathname }).toString();
      if (window.location.pathname !== '/down-for-maintenance') {
        window.location.href = url.toString();
      }
    }
    return Promise.reject(error);
  },
);

export { instance };
