import suppliers from './suppliers';

export const live = { ...suppliers };

const api = { ...live };
export default api;

export const replaceAPI = (newApi: Record<string, any>) => {
  Object.keys(newApi).forEach((i) => {
    (api as any)[i] = newApi[i];
  });
};
