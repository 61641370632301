import type { MutationTree } from 'vuex';

const initialState = () => ({
  loading: false,
  status: 'all',
  name: '',
  sort: 'dateCreated',
  sortDirection: 'desc',
  sentBy: '',
  loaded: false,
  invites: [],
  meta: {
    limit: 50,
    offset: 0,
    total: 0,
  },
  counts: {
    total: 0,
    pending: 0,
    accepted: 0,
    expired: 0,
    cancelled: 0,
    me: 0,
  },
});

export const state = initialState();

export const mutations: MutationTree<any> = {
  SET_INVITE_STATUS(state, data) {
    state.meta.offset = 0;
    state.status = data.status;
  },
  SET_INVITE_SENT_BY(state, data) {
    state.meta.offset = 0;
    state.sentBy = data.sentBy;
  },
  SET_INVITE_NAME(state, data) {
    state.meta.offset = 0;
    state.name = data.name;
  },
  SET_INVITE_SORT(state, data) {
    state.meta.offset = 0;
    state.sort = data.sort;
    state.sortDirection = data.sortDirection;
  },
  SET_OFFSET(state, offset) {
    state.meta.offset = offset;
  },
  SET_INVITES(state, data) {
    state.invites = data.invites;
    state.meta = data.metadata;
    state.loaded = true;
    state.loading = false;
  },
  SET_INVITES_COUNTS(state, data) {
    state.counts = data;
  },
  RESEND_INVITE(state, inv) {
    const inviteIdx = state.invites.findIndex((value: any) => value.id === inv.id);
    if (inviteIdx >= 0) {
      state.invites.splice(inviteIdx, 1, { ...inv, resent: true });
    }
  },
  CANCEL_INVITE(state, inviteID) {
    const invite = state.invites.find((value: any) => value.id === inviteID);
    if (invite) {
      invite.cancelled = true;
      invite.cancelledAt = new Date();
    }
  },
  START_LOADING(state) {
    state.loading = true;
  },
  CLEAR_STATE(state) {
    Object.assign(state, initialState());
  },
};
