import { request } from '@/api/request';

const getSupplierCustomLabels = (supplierID: string) =>
  request('GET', `/supplier/${supplierID}/labels`);

const setSupplierCustomLabels = (supplierID: string, labels: string[]) =>
  request('PUT', `/supplier/${supplierID}/labels`, {
    labelIDs: labels,
  });

export default {
  getSupplierCustomLabels,
  setSupplierCustomLabels,
};
