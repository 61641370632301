import { useQuery } from '@tanstack/vue-query';
import { mergeQueryOptions, type QueryOptions } from '@/modules/query/utils';
import api from './api';
import type {
  SupplierInfoResponse,
  ClientInfoResponse,
  SubEntityInfoResponse,
  InviteInfoResponse,
  FederatedInviteInfoResponse,
} from './api';

export const SUPPLIER_INFO_QUERY = '/supplier/:id/info';
export function useSupplierInfoByOrgID(
  orgID: MaybeRefOrGetter<string>,
  options?: QueryOptions<SupplierInfoResponse, readonly [string, string]>,
) {
  const query = useQuery(
    mergeQueryOptions(options, {
      queryKey: [SUPPLIER_INFO_QUERY, toRef(orgID)],
      async queryFn({ queryKey }) {
        const res = await api.fetchSupplierInfoByOrgID(queryKey[1]);
        return res.data;
      },
    }),
  );

  return { ...query, supplierInfo: query.data };
}

export const CLIENT_INFO_QUERY = '/client/:id/info';
export function useClientInfoByOrgID(
  orgID: MaybeRefOrGetter<string>,
  options?: QueryOptions<ClientInfoResponse, readonly [string, string]>,
) {
  const query = useQuery(
    mergeQueryOptions(options, {
      queryKey: [CLIENT_INFO_QUERY, toRef(orgID)],
      async queryFn({ queryKey }) {
        const res = await api.fetchClientInfoByOrgID(queryKey[1]);
        return res.data;
      },
    }),
  );

  return { ...query, clientInfo: query.data };
}

export const SUB_ENTITY_INFO_QUERY = '/federatedsubentities/:id/info';
export function useSubEntityInfoByOrgID(
  orgID: MaybeRefOrGetter<string>,
  options?: QueryOptions<SubEntityInfoResponse, readonly [string, string]>,
) {
  const query = useQuery(
    mergeQueryOptions(options, {
      queryKey: [SUB_ENTITY_INFO_QUERY, toRef(orgID)],
      async queryFn({ queryKey }) {
        const res = await api.fetchSubEntityInfoByOrgID(queryKey[1]);
        return res.data;
      },
    }),
  );

  return { ...query, subEntityInfo: query.data };
}

export function useOrgInfoByID(
  type: MaybeRefOrGetter<'client' | 'supplier' | 'federated'>,
  orgID: MaybeRefOrGetter<string>,
) {
  const supplierInfoQuery = useSupplierInfoByOrgID(orgID, {
    enabled: () => toValue(type) === 'supplier',
  });
  const clientInfoQuery = useClientInfoByOrgID(orgID, {
    enabled: () => toValue(type) === 'client',
  });
  const subEntityInfoQuery = useSubEntityInfoByOrgID(orgID, {
    enabled: () => toValue(type) === 'federated',
  });

  const orgInfo = computed(() => {
    if (toValue(type) === 'supplier') return supplierInfoQuery.data.value;
    if (toValue(type) === 'client') return clientInfoQuery.data.value;
    return subEntityInfoQuery.data.value;
  });

  const isLoading = computed(() => {
    if (toValue(type) === 'supplier') return supplierInfoQuery.isLoading.value;
    if (toValue(type) === 'client') return clientInfoQuery.isLoading.value;
    return subEntityInfoQuery.isLoading.value;
  });

  const isError = computed(() => {
    if (toValue(type) === 'supplier') return supplierInfoQuery.isError.value;
    if (toValue(type) === 'client') return clientInfoQuery.isError.value;
    return subEntityInfoQuery.isError.value;
  });

  function refetch() {
    if (toValue(type) === 'supplier') return supplierInfoQuery.refetch();
    if (toValue(type) === 'client') return clientInfoQuery.refetch();
    return subEntityInfoQuery.refetch();
  }

  return { orgInfo, isLoading, isError, refetch };
}

export const INVITE_INFO_QUERY = '/invites/accepted';
export function useInviteInfoByOrgID(
  orgID: MaybeRefOrGetter<string>,
  options?: QueryOptions<InviteInfoResponse, readonly [string, string]>,
) {
  const query = useQuery(
    mergeQueryOptions(options, {
      queryKey: [INVITE_INFO_QUERY, toRef(orgID)],
      async queryFn({ queryKey }) {
        const res = await api.fetchInviteInfoByOrgID(queryKey[1]);
        return res.data;
      },
    }),
  );

  const isError = computed(() => {
    const status = query.error.value?.response?.status;
    return !!status && status !== 404;
  });

  return { ...query, isError, inviteInfo: query.data };
}

export const FEDERATED_INVITE_INFO_QUERY = '/federatedinvites/accepted';
export function useFederatedInviteInfoByOrgID(
  orgID: MaybeRefOrGetter<string>,
  options?: QueryOptions<FederatedInviteInfoResponse, readonly [string, string]>,
) {
  const query = useQuery(
    mergeQueryOptions(options, {
      queryKey: [FEDERATED_INVITE_INFO_QUERY, toRef(orgID)],
      async queryFn({ queryKey }) {
        const res = await api.fetchFederatedInviteInfoByOrgID(queryKey[1]);
        return res.data;
      },
    }),
  );

  const isError = computed(() => {
    const status = query.error.value?.response?.status;
    return !!status && status !== 404;
  });

  return { ...query, isError, federatedInviteInfo: query.data };
}

export function useInviteOrFederatedInviteInfoByID(
  type: MaybeRefOrGetter<'client' | 'supplier' | 'federated'>,
  orgID: MaybeRefOrGetter<string>,
) {
  const nonFederatedInviteInfoQuery = useInviteInfoByOrgID(orgID, {
    enabled: () => toValue(type) !== 'federated',
  });
  const federatedInviteInfoQuery = useFederatedInviteInfoByOrgID(orgID, {
    enabled: () => toValue(type) === 'federated',
  });

  const inviteInfo = computed(() => {
    if (toValue(type) === 'federated') return federatedInviteInfoQuery.data.value;
    return nonFederatedInviteInfoQuery.data.value;
  });

  const isLoading = computed(() => {
    if (toValue(type) === 'federated') return federatedInviteInfoQuery.isLoading.value;
    return nonFederatedInviteInfoQuery.isLoading.value;
  });

  const isError = computed(() => {
    if (toValue(type) === 'federated') return federatedInviteInfoQuery.isError.value;
    return nonFederatedInviteInfoQuery.isError.value;
  });

  function refetch() {
    if (toValue(type) === 'federated') {
      federatedInviteInfoQuery.refetch();
    } else {
      nonFederatedInviteInfoQuery.refetch();
    }
  }

  return { inviteInfo, isLoading, isError, refetch };
}
