import { AxiosError } from 'axios';
import type { Action } from 'vuex';
import { instance as axios } from '@/api/instance';

const API_ERROR = 'API_ERROR';
const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export const useApi = () => {
  const use = ['test'].includes(import.meta.env.MODE);
  return use;
};

/* eslint-disable camelcase */
export const apiCall: Action<any, any> = async (
  { commit, dispatch },
  {
    url,
    method = 'get',
    options = {},
    request,
    success,
    error,
    globalError = true,
    loading_flag,
    debug_response = {},
    debug_error,
    meta = {},
    force_use_api,
  },
) => {
  if (request) commit(request, { meta });
  if (loading_flag) {
    commit('SET_API_LOADING', { flag: loading_flag, loading: true });
  }
  try {
    let response;
    let data;

    if (useApi() || force_use_api) {
      response = (await (axios as any)[method](url, options)) || { data: null };
      data = response.data;
    } else {
      // Simulate network delay
      await sleep(Math.random() * 300);
      if (debug_error) {
        throw debug_error;
      } else {
        response = debug_response;
        data = response;
      }
    }
    if (success) commit(success, { data, meta, response });

    return response;
  } catch (err) {
    if (!(err instanceof AxiosError)) throw err;

    const { response } = err;
    const { data = { message: 'Server Error' }, status } = response || {};
    if (error) {
      commit(error, {
        error: err,
        data,
        meta,
        response,
      });
    } else if (globalError) {
      dispatch('setGlobalApiError');
    }
    // console.log('error', url, method, options);
    return { error: err, data, status };
  } finally {
    if (loading_flag) {
      commit('SET_API_LOADING', { flag: loading_flag, loading: false });
    }
  }
};
/* eslint-enable camelcase */

export const setGlobalApiError: Action<any, any> = ({ commit }) => {
  commit('SET_API_ERROR', API_ERROR);
};

export const setGlobalError: Action<any, any> = ({ commit }, error) => {
  commit('SET_API_ERROR', error);
};

export const clearGlobalError: Action<any, any> = ({ commit }) => {
  commit('SET_API_ERROR', null);
};
