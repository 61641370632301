import { request } from '@/api/request';
import type { ReassessmentStatus } from '@/types/models/reassessment';

const startReassessment = () => request('POST', `/reassessment/start`);

const submitReassessment = () => request('POST', `/reassessment/complete`);

const getReassessmentStatus = () => request('GET', '/reassessment/status');

const confirmDomain = (domainID: string) =>
  request('POST', `/reassessment/domain/${domainID}/confirm`);

const setAnswerConfirmation = (answerID: string, isConfirmed: boolean) =>
  request<ReassessmentStatus>('POST', `/reassessment/answers/${answerID}/setconfirmation`, {
    confirmed: isConfirmed,
  });

export default {
  startReassessment,
  submitReassessment,
  getReassessmentStatus,
  confirmDomain,
  setAnswerConfirmation,
};
