import { request } from '@/api/request';

const fetchInvite = (id: string) => request('GET', `/federatedinvites/${id}`);

const fetchInvites = () => request('GET', '/federatedinvites');

const sendInvite = (organisationName: string, contactEmail: string) =>
  request('POST', '/federatedinvites', { organisationName, contactEmail });

const cancelInvite = (id: string) => request('POST', `/federatedinvites/${id}/cancel`);

const acceptInvite = (id: string) => request('POST', `/federatedinvites/${id}/accept`);

export const live = {
  fetchInvite,
  fetchInvites,
  sendInvite,
  cancelInvite,
  acceptInvite,
};

const api = { ...live };
export default api;

export const replaceAPI = (newApi: Record<string, any>) => {
  Object.keys(newApi).forEach((i) => {
    (api as any)[i] = newApi[i];
  });
};
