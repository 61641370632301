import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query';
import type { MutationObserverOptions } from '@tanstack/vue-query';
import type { AxiosError } from 'axios';
import { useStore } from '@/store/useStore';
import { mergeMutationOptions, mergeQueryOptions, type QueryOptions } from '@/modules/query/utils';
import type { Answer, ScopingAnswer } from '@/types/models/answer';
import type { QuickAnswerSuggestion } from '@/types/models/quickAnswer';
import { useFramework, useCombinedFrameworkConfig } from '@/modules/framework/composables';
import useFeatureFlags, { FeatureFlag } from '@/composables/feature-flags';
import type { ReassessmentStatus } from '@/types/models/reassessment';
import api from './api';
import type { ControlAnswersResponse } from './api/assessment';

// ASSESSMENTS

export const ASSESSMENT_ANSWERS_QUERY = '/assessment/answers';

export function useControlAnswers(options?: QueryOptions<ControlAnswersResponse>) {
  const query = useQuery(
    mergeQueryOptions(options, {
      queryKey: [ASSESSMENT_ANSWERS_QUERY],
      async queryFn() {
        const res = await api.getControlAnswers();
        return res.data;
      },
    }),
  );

  const { dependentControls, controlsByID } = useFramework();

  // ALWAYS use this instead of query.data, as this has an eventual consistency fix
  const controlAnswers = computed(
    () =>
      query.data.value?.map((answer) => {
        const control = controlsByID.value[answer.controlID];

        // This horrific code is to work around an eventual consistency issue
        // on updating a control where child controls might not have been
        // correctly marked as not applicable yet as it happens on an event
        if (
          control?.parentID &&
          dependentControls.value[control.parentID]?.includes(control.controlID)
        ) {
          const parentAnswer = query.data.value?.find(
            (otherAnswer) => otherAnswer.controlID === control.parentID,
          );
          if (!parentAnswer) return answer;

          let notApplicable: boolean;
          try {
            const parsedAnswer = JSON.parse(parentAnswer.answer);
            // answer is stringified JSON e.g. for YesCompliantWithExpiryControl control type
            notApplicable = control.ifParentAnswer !== parsedAnswer.answer;
          } catch {
            // answer is not stringified JSON
            notApplicable = control.ifParentAnswer !== parentAnswer.answer;
          }

          return { ...answer, notApplicable };
        }
        return answer;
      }) || [],
  );

  /** @deprecated use controlAnswersByControlID instead */
  const orgAnswerOnlyByControlID = computed(() =>
    Object.fromEntries(controlAnswers.value.map((answer) => [answer.controlID, answer])),
  );

  const controlAnswersByControlID = computed(() =>
    Object.groupBy(controlAnswers.value, (answer) => answer.controlID),
  );

  // Sync with semi-deprecated store
  const store = useStore();
  watchEffect(() => {
    if (store) {
      store.commit('assessment/SET_CONTROL_ANSWERS', orgAnswerOnlyByControlID.value);
    }
  });

  function getByAnswerID(answerID: string) {
    for (const controlAnswer of controlAnswers.value) {
      if (controlAnswer.answerID === answerID) {
        return controlAnswer;
      }
    }

    return null;
  }

  const queryClient = useQueryClient();

  // THIS CODE IS GROSS PLEASE AVOID COPYING THIS PATTERN
  // It's a quick(ish) replacement of some code that was updating the Vuex store
  // and relying on a side effect in an unknown place to actually save the data
  // to the server. The same side effect works through the tanstack query store,
  // so this gross code remains. TODO: (2 hours) unpick and fix this!!
  function dangerouslyApplySuggestions(suggestions: QuickAnswerSuggestion[]) {
    queryClient.setQueryData<ControlAnswersResponse>([ASSESSMENT_ANSWERS_QUERY], (oldData) => {
      if (!oldData) return undefined;

      const newData = oldData.slice();

      for (const suggestion of suggestions) {
        // This CAN be undefined (and usually is)
        const existingAnswer = newData.find((answer) => answer.controlID === suggestion.controlId);

        const newAnswer = {
          ...existingAnswer,
          controlID: suggestion.controlId,
          answer: suggestion.suggestedAnswer,
          notes: suggestion.suggestedNotes,
          documentIDs: suggestion.suggestedEvidence,
        } as Answer; // TODO: when existingAnswer is undefined this is very much not valid

        if (existingAnswer) {
          newData.splice(newData.indexOf(existingAnswer), 1, newAnswer);
        } else {
          newData.push(newAnswer);
        }
      }

      return newData;
    });
  }

  return {
    ...query,
    controlAnswers,
    orgAnswerOnlyByControlID,
    controlAnswersByControlID,
    getByAnswerID,
    dangerouslyApplySuggestions,
  };
}

export function useScopingComplete(
  options?: MutationObserverOptions<void, AxiosError, void, null>,
) {
  const store = useStore();

  return useMutation(
    mergeMutationOptions(options, {
      async mutationFn() {
        await api.completeScopingQuestions();
      },
      async onSuccess(_data) {
        trackEvent('scoping:completed');
        // Revalidate assessment status
        await store.dispatch('assessment/getAssessmentStatus');
      },
    }),
  );
}

export function useConfirmScopingAnswer(
  options?: MutationObserverOptions<ScopingAnswer, AxiosError, string, null>,
) {
  const store = useStore();

  return useMutation(
    mergeMutationOptions(options, {
      async mutationFn(domainId) {
        const res = await api.confirmScopingAnswer(domainId);
        return res.data;
      },
      onSuccess(data) {
        store.commit('assessment/CHANGE_SCOPING_ANSWER', data);
      },
    }),
  );
}

export const NEW_IN_SCOPE_DOMAINS_QUERY = '/quickanswer/newInScopeDomains';

export function useNewInscopeDomains(options?: QueryOptions<string[]>) {
  const query = useQuery(
    mergeQueryOptions(options, {
      queryKey: [NEW_IN_SCOPE_DOMAINS_QUERY],
      async queryFn() {
        const res = await api.getNewInscopeDomains();
        return res.data;
      },
    }),
  );

  return { ...query, newInscopeDomains: computed(() => query.data.value || []) };
}

export function useRequiringAttention() {
  const store = useStore();
  const { ff } = useFeatureFlags();

  const { sortedControls, sortedDomains, domainsByID } = useFramework();

  const { frameworkRequiredDomainIDs, frameworkRequiredControlIDs } = useCombinedFrameworkConfig();

  const controlsRequiringAttention = computed(() => {
    const answers = store.state.assessment.controlAnswers;
    const outOfScope = store.getters['assessment/outOfScope'];
    const isReassessing = store.getters['assessment/isReassessing'];
    const scopingAnswers = store.state.assessment.scopingAnswers;

    return sortedControls.value
      .filter((control) => {
        if (
          ff(FeatureFlag.FrameworkAddOns) &&
          !frameworkRequiredControlIDs.value.includes(control.controlID)
        ) {
          return false;
        }

        if (control.deprecated || outOfScope[control.domainID])
          // Ignore deprecated and out of scope
          return false;

        // Add controls that changed meaning
        const answer = answers[control.controlID];
        if (answer && !answer.notApplicable && answer.unconfirmedControlUpdate) {
          return true;
        }

        // If reassessing then also add unanswered applicable controls (whose scoping question was answered)
        if (isReassessing) {
          if (
            !domainsByID.value[control.domainID]?.scopingQuestion ||
            scopingAnswers[control.domainID]
          ) {
            if (!answer || (!answer.answer && !answer.notApplicable)) {
              return true;
            }
          }
        }

        return false;
      })
      .map((control) => control.controlID);
  });

  const scopingQuestionsRequiringAttention = computed(() =>
    sortedDomains.value
      .filter((domain) => {
        if (
          ff(FeatureFlag.FrameworkAddOns) &&
          !frameworkRequiredDomainIDs.value.includes(domain.domainID)
        )
          return false;

        // Ignore deprecated and domains without scoping questions
        if (domain.deprecated || !domain.scopingQuestion) return false;

        const answer = store.state.assessment.scopingAnswers[domain.domainID];
        // Ignore answered scoping questions that are confirmed
        if (answer && !answer.unconfirmedScopingControlUpdate) return false;

        return true;
      })
      .map((domain) => domain.domainID),
  );

  const totalNumberRequiringAttention = computed(
    () => controlsRequiringAttention.value.length + scopingQuestionsRequiringAttention.value.length,
  );

  return {
    controlsRequiringAttention,
    scopingQuestionsRequiringAttention,
    totalNumberRequiringAttention,
  };
}

export function useRemoveControlAnswer(
  options?: MutationObserverOptions<ControlAnswersResponse, AxiosError, string, null>,
) {
  const queryClient = useQueryClient();

  return useMutation(
    mergeMutationOptions(options, {
      async mutationFn(controlID) {
        const res = await api.removeControlAnswer(controlID);
        return res.data;
      },
      onSuccess(_data) {
        queryClient.invalidateQueries({ queryKey: [ASSESSMENT_ANSWERS_QUERY] });
      },
    }),
  );
}

// REASSESSMENTS
export const REASSESMENT_STATUS_QUERY = '/reassessment/status';

export function useReassessmentStatus(options?: QueryOptions<ReassessmentStatus>) {
  const query = useQuery(
    mergeQueryOptions(options, {
      queryKey: [REASSESMENT_STATUS_QUERY],
      async queryFn() {
        const res = await api.getReassessmentStatus();
        return res.data;
      },
    }),
  );

  return { ...query, reassessmentStatus: computed(() => query.data.value) };
}

export function useSetControlAnswerConfirmation(
  options?: MutationObserverOptions<
    ReassessmentStatus,
    AxiosError,
    { answerID: string; isConfirmed: boolean },
    null
  >,
) {
  const queryClient = useQueryClient();

  return useMutation(
    mergeMutationOptions(options, {
      async mutationFn({ answerID, isConfirmed }) {
        const res = await api.setAnswerConfirmation(answerID, isConfirmed);
        return res.data;
      },
      onSuccess(newData, variables) {
        queryClient.setQueryData<ReassessmentStatus>([REASSESMENT_STATUS_QUERY], (oldData) => {
          if (!oldData) return undefined;

          if (oldData.answerConfirmations.find((ac) => ac.answerID === variables.answerID)) {
            return {
              ...oldData,
              answerConfirmations: oldData.answerConfirmations.map((ac) =>
                ac.answerID === variables.answerID
                  ? { ...ac, confirmed: variables.isConfirmed }
                  : ac,
              ),
            };
          }

          const newAnswerConfirmations = (
            newData as unknown as { reassessmentStatus: ReassessmentStatus }
          ).reassessmentStatus.answerConfirmations;

          return {
            ...oldData,
            answerConfirmations: newAnswerConfirmations ?? oldData.answerConfirmations,
          };
        });
      },
    }),
  );
}

export function useArchiveProductAnswer(
  options?: MutationObserverOptions<
    null,
    AxiosError,
    { productID: string; answerID: string },
    null
  >,
) {
  const queryClient = useQueryClient();

  return useMutation(
    mergeMutationOptions(options, {
      async mutationFn({ productID, answerID }) {
        const res = await api.archiveProductAnswer(productID, answerID);
        return res.data;
      },
      onSuccess(_data) {
        queryClient.invalidateQueries({ queryKey: [ASSESSMENT_ANSWERS_QUERY] });
      },
    }),
  );
}
