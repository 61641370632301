import type { MutationTree } from 'vuex';

const state = {
  attributes: {},
};

const mutations: MutationTree<any> = {
  SET_COGNITO_USER_ATTRIBUTES(state, userAttributes) {
    state.attributes = userAttributes;
  },
};

export default {
  state,
  mutations,
};
