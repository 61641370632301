import { request } from '@/api/request';
import type { Connection } from '@/types/models/connection';
import type { MergeRequest } from '@/types/models/merge-request';

const inviteSupplier = (
  organisationName: string,
  contactEmail: string,
  primerEmail: string,
  unclaimedOrgID?: string,
) =>
  request('POST', '/invites', {
    organisationName,
    contactEmail,
    primerEmail,
    unclaimedOrgID,
  });

const connectToSupplier = (orgID: string) =>
  request('POST', `/orgs/${orgID}/connection`, {
    type: 'supplier',
  });

const inviteSuggest = (orgName: string, orgEmail: string) =>
  request('GET', '/invites/suggest', null, {
    orgName,
    orgEmail,
    limit: 3,
  });

type Invite = {
  id: string;
  senderName: string;
  recipientOrgName: string;
  recipientEmail: string;
  createdAt: string;
  expired: boolean;
  expiresAt?: string;
  accepted: boolean;
  acceptedAt?: string;
  acceptedByOrgID?: string;
  acceptedByOrgLogo?: string;
  resentAt?: string;
  cancelled: boolean;
  cancelledAt?: string;
  unclaimedOrgID: string;
};

export type InvitesResponse = {
  invites: Invite[];
  metadata: {
    limit: number;
    offset: number;
    total: number;
    name: string;
    sort: string;
    sentBy: string;
    sortDirection: string;
  };
};

const getInvites = (status: string, meta: Partial<InvitesResponse['metadata']>) =>
  request<InvitesResponse>('GET', '/invites', null, {
    status,
    limit: meta.limit,
    offset: meta.offset,
    name: meta.name,
    sort: meta.sort,
    sentBy: meta.sentBy,
    sortDirection: meta.sortDirection,
  });

const getInvitesCounts = () => request('GET', '/invites/counts');

const resendInvite = (inviteID: string) => request('POST', `/invites/${inviteID}/resend`);

const cancelInvite = (inviteID: string) => request('POST', `/invites/${inviteID}/cancel`);

const getInviteInfo = (inviteID: string) => request('GET', `/invites/${inviteID}`);

export type RedeemInviteResponse = {
  mergeRequest?: MergeRequest;
  connection: Connection;
};

const redeemInvite = (inviteID: string) =>
  request<RedeemInviteResponse>('POST', `/invites/${inviteID}/redeem`);

export default {
  inviteSupplier,
  connectToSupplier,
  inviteSuggest,
  getInvites,
  resendInvite,
  cancelInvite,
  getInviteInfo,
  redeemInvite,
  getInvitesCounts,
};
