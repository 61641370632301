import type { Action } from 'vuex';
import org from './__tests__/organisation.json';

export const setSignupOrganisation: Action<any, any> = ({ commit }, organisation) => {
  commit('SET_SIGNUP_ORGANISATION', { ...organisation });
};

export const setSignupUser: Action<any, any> = ({ commit }, user) => {
  commit('SET_SIGNUP_USER', { ...user });
};

export const clearInviteInfo: Action<any, any> = ({ commit }) => {
  commit('CLEAR_INVITE_INFO');
};

export const getInviteInfo: Action<any, any> = ({ dispatch }, inviteId) =>
  dispatch('apiCall', {
    url: `/invites/${inviteId}`,
    force_use_api: true,
    success: 'SET_INVITE_INFO',
    error: 'CLEAR_INVITE_INFO',
  });

export const getFederatedInviteInfo: Action<any, any> = ({ dispatch }, inviteId) =>
  dispatch('apiCall', {
    url: `/federatedinvites/${inviteId}`,
    force_use_api: true,
    success: 'SET_INVITE_INFO',
    error: 'CLEAR_INVITE_INFO',
  });

export const checkOrganisationExists: Action<any, any> = ({ dispatch }, organisation) => {
  const options = {
    params: {
      name: organisation.legal_name,
      location: organisation.country,
    },
  };
  return dispatch('apiCall', {
    url: '/orgs/exists',
    force_use_api: true,
    options,
    success: 'SET_ORG_DOES_EXIST',
    error: 'SET_ORG_NOT_EXISTS',
    debug_error: new Error('No organisation'),
  });
};

export const setOrganisationExists: Action<any, any> = ({ commit }, exists) => {
  commit('SET_ORGANISATION_EXISTS', exists);
};

export const setCanUpdatePhoneNumber: Action<any, any> = ({ commit }, canUpdate) => {
  commit('SET_CAN_UPDATE_PHONE_NUMBER', canUpdate);
};

export const createOrganisationAndLeadUser: Action<any, any> = (
  { commit, dispatch },
  { organisation, user, signupSource, httpReferrer },
) => {
  const data = {
    org: {
      name: organisation.legal_name,
      companyNumber: organisation.company_no,
      location: organisation.country,
      tradingNames: organisation.trading_names,
      streetAddress: organisation.street_address,
      city: organisation.city,
      postalCode: organisation.postcode,
      sector: organisation.industry,
      website: organisation.url,
      businessPhoneNumber: organisation.business_phone_number,
      signupSourceType: signupSource?.type,
      signupSourceID: signupSource?.id,
    },
    user: {
      email: user.email,
      firstName: user.given_name,
      lastName: user.family_name,
      phone: user.phone,
      marketingEmails: user.marketing_emails,
      team: user.team,
      jobTitle: user.job_title,
    },
    httpReferrer,
  };
  commit('SET_SIGNUP_ERROR', { data: { message: null } });
  dispatch('setCreateOrgError', null);
  dispatch('setCreateUserError', null);
  return dispatch('apiCall', {
    url: '/neworg',
    force_use_api: true,
    method: 'post',
    options: data,
    success: 'SET_ORGANISATION_AND_USER_ID',
    error: 'SET_SIGNUP_ERROR',
    debug_response: org,
  });
};

export const setForgotEmail: Action<any, any> = ({ commit }, email) => {
  commit('SET_FORGOT_EMAIL', email);
};

export const setCreateOrgError: Action<any, any> = ({ commit }, error) => {
  commit('SET_CREATE_ORG_ERROR', error);
};

export const setCreateUserError: Action<any, any> = ({ commit }, error) => {
  commit('SET_CREATE_USER_ERROR', error);
};

export const setSubmitted: Action<any, any> = ({ commit }) => {
  commit('SET_SUBMITTED');
};
