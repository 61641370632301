import { request } from '@/api/request';
import type { DatasetRequest, DatasetResponse } from '@/libs/search/types';
import type { EmergingThreatSpecificQuestionAnswer } from '@/types/models/emerging-threats';

export type EmergingThreatsSupplierResponse_Answer = {
  answer: string;
  hasNotes: boolean;
  hasEvidence: boolean;
};
export type EmergingThreatsSupplierResponse = {
  id: string;
  name: string;
  logoUrl: string;
  location: string;
  criticality: string;
  confidentiality: string;
  sector: string;
  holdsPII: string;
  labels: string[];
  assignedUsers: string[];
  assignedToMe: boolean;
  responseStatus: string;
  respondedAt?: string;
  modifiedAt?: string;
  specificQuestions: Record<string, EmergingThreatsSupplierResponse_Answer>;
};
export type SuppliersResponse = DatasetResponse & {
  suppliers: EmergingThreatsSupplierResponse[];
  totalSuppliers: number;
};
const fetchClientEmergingThreatSupplierList = (id: string, payload: DatasetRequest) =>
  request<SuppliersResponse>('POST', `/emergingthreats/${id}/suppliers`, payload);

// This is just called "EmergingThreat" on the backend, but is specific to this response
export type EmergingThreatForClientsList = {
  id: string;
  name: string;
  type: string;
  emergedAt: string;
  summary: string;
  guideLink: string;
  status: string;
  publishedAt: string;
  remediatingSuppliers: number;
  unansweredSuppliers: number;
  investigatingSuppliers: number;
  resolvedSuppliers: number;
  unaffectedSuppliers: number;
};
export type EmergingThreatsListForClientsResponse = DatasetResponse & {
  totalEmergingThreats: number;
  emergingThreats: EmergingThreatForClientsList[];
};
const fetchEmergingThreatsListForClients = (payload: DatasetRequest) =>
  request<EmergingThreatsListForClientsResponse>(
    'POST',
    '/emergingthreats/listforclients',
    payload,
  );

export type EmergingThreatClientMetricsResponse = {
  responseStatus: Record<string, { count: number; change: number }>;
  specificQuestionsAnswers: Record<string, { yes: number; no: number; unanswered: number }>;
};
const fetchEmergingThreatClientMetrics = (threatID: string, criticality: string) =>
  request('GET', `/emergingthreats/${threatID}/metrics`, null, { criticality });

const fetchSupplierCountPotentialThreats = (supplierID: string) =>
  request('GET', `/emergingthreats/supplier/${supplierID}/potentialthreats`);

const fetchEmergingThreatListWithSupplierResponse = (supplierID: string) =>
  request('GET', `/emergingthreats/supplier/${supplierID}/threatlist`);

export type EmergingThreatSupplierQuestionAnswersResponse = Record<
  string,
  EmergingThreatSpecificQuestionAnswer
>;

const fetchEmergingThreatSupplierQuestionAnswers = (threatID: string, supplierID: string) =>
  request<EmergingThreatSupplierQuestionAnswersResponse>(
    'GET',
    `/emergingthreats/${threatID}/supplier/${supplierID}/answers`,
  );

export type EmergingThreatsSupplierQuestionsAnswersResponse = Record<
  string,
  EmergingThreatSpecificQuestionAnswer
>;

const fetchEmergingThreatsSupplierQuestionAnswers = (supplierID: string) =>
  request<EmergingThreatsSupplierQuestionsAnswersResponse>(
    'GET',
    `/emergingthreats/supplier/${supplierID}/answers`,
  );

export const live = {
  fetchClientEmergingThreatSupplierList,
  fetchEmergingThreatsListForClients,
  fetchEmergingThreatClientMetrics,
  fetchSupplierCountPotentialThreats,
  fetchEmergingThreatListWithSupplierResponse,
  fetchEmergingThreatSupplierQuestionAnswers,
  fetchEmergingThreatsSupplierQuestionAnswers,
};

const api = { ...live };
export default api;

export const replaceAPI = (newApi: Record<string, any>) => {
  Object.keys(newApi).forEach((i) => {
    (api as any)[i] = newApi[i];
  });
};
