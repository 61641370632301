import type { Action } from 'vuex';
import api from '../api';

const markCommentsAsSeen: Action<any, any> = async ({ commit }, { discussionId, commentIds }) => {
  await api.markCommentsAsSeen(discussionId, commentIds);
  commit('SET_COMMENTS_AS_SEEN', { discussionId, commentIds });
};

const markCommentAsVisible: Action<any, any> = ({ commit }, { discussionId, commentId }) => {
  commit('SET_COMMENT_AS_VISIBLE', { discussionId, commentId });
};

const getDiscussionPreviews: Action<any, any> = async (
  { commit },
  { role, participationType, archived, orgFilter, page, limit = 15 },
) => {
  const res = await api.getDiscussionPreviews({
    role,
    participationType,
    archived,
    orgFilter,
    page,
    limit,
  });

  // if page is greater than 0 then
  // we should be adding the discussions
  // to the currently stored ones.
  if (page > 0) {
    commit('ADD_DISCUSSION_PREVIEWS', res.data.discussions);
  } else {
    commit('SET_DISCUSSION_PREVIEWS', res.data.discussions);
  }
  commit('SET_DISCUSSIONS', res.data);
  commit('SET_USERS', res.data);
  commit('SET_ORGS', res.data);

  return res.data.discussions.length === limit;
};

const getDiscussion: Action<any, any> = async ({ commit }, discussionID) => {
  const res = await api.getDiscussion(discussionID);
  commit('SET_DISCUSSIONS', { discussions: [res.data.discussion] });
  commit('SET_USERS', res.data);
  commit('SET_ORGS', res.data);
};

const getDiscussionsByAssociatedID: Action<any, any> = async ({ commit }, associatedID) => {
  const res = await api.getDiscussionsByAssociatedID(associatedID);
  commit('SET_DISCUSSIONS', res.data);
  commit('SET_USERS', res.data);
  commit('SET_ORGS', res.data);
};

const startDiscussion: Action<any, any> = async (
  { commit, rootGetters },
  { associatedID, associatedType, message, clientID, supplierID, attachments },
) => {
  attachments = attachments.map((a: any) => {
    // Files may have different field for file ID; make sure we send it here as `uploadID`.
    a.uploadID = rootGetters['uploads/files/getFileID'](a);
    return a;
  });

  const res = await api.startDiscussion(
    associatedID,
    associatedType,
    message,
    clientID,
    supplierID,
    attachments,
  );
  commit('START_DISCUSSION', res.data);
};

const addComment: Action<any, any> = async (
  { commit, rootGetters },
  { discussionID, message, attachments },
) => {
  attachments = attachments.map((a: any) => {
    // Files may have different field for file ID; make sure we send it here as `uploadID`.
    a.uploadID = rootGetters['uploads/files/getFileID'](a);
    return a;
  });

  const res = await api.replyDiscussion(discussionID, message, attachments);
  commit('ADD_COMMENT', res.data);
};

const archiveDiscussion: Action<any, any> = async ({ commit }, discussionID) => {
  const res = await api.archiveDiscussion(discussionID);
  commit('ARCHIVE_DISCUSSION', {
    discussionID,
    ...res.data,
  });
};

const unarchiveDiscussion: Action<any, any> = async ({ commit }, discussionID) => {
  const res = await api.unarchiveDiscussion(discussionID);
  commit('UNARCHIVE_DISCUSSION', {
    discussionID,
    ...res.data,
  });
};

const setDiscussionFilters: Action<any, any> = ({ commit }, filters) => {
  commit('SET_DISCUSSION_FILTERS', filters);
};

const clearDiscussionsStore: Action<any, any> = ({ commit }) => {
  commit('CLEAR_STATE');
};

export const actions = {
  getDiscussionsByAssociatedID,
  getDiscussion,
  getDiscussionPreviews,
  startDiscussion,
  addComment,
  markCommentAsVisible,
  markCommentsAsSeen,
  archiveDiscussion,
  unarchiveDiscussion,
  setDiscussionFilters,
  clearDiscussionsStore,
};
