import axios from 'axios';
import config from '@/config/api';
import { request } from '@/api/request';

const axiosInstance = axios.create({
  baseURL: config.base_url,
});

const refreshAccessToken = (refreshToken: string) =>
  axiosInstance.post<{ accessToken: string }>('/sso/refreshtoken', { refreshToken });

const ssoSignIn = (email: string) => axiosInstance.post('/sso/auth', { email });

const ssoCallback = (code: string) => axiosInstance.post('/sso/login', { code });

const ssoOAuthSignIn = (provider: string) => axiosInstance.post('/sso/oauth', { provider });

const ssoOAuthCallback = (code: string) => axiosInstance.post('/sso/oauth/login', { code });

export type SSOStatus = {
  connections: { domains: string[]; id: string; name: string; state: string; type: string }[];
  provisioningEnabled: boolean;
  provisioningRole: string;
  provisioningRoleID: string;
  ssoEnabled: boolean;
  ssoEnforced: boolean;
};
const fetchSSOStatus = () => request<SSOStatus>('GET', '/sso/status');

const ssoSetup = (domains?: string[]) => request('POST', '/sso/setup', { domains });

const enforceSSO = (status: string) => request('PUT', '/sso/enforce', { enforceSSO: status });

const provisionUsersSSO = (status: boolean, roleID: string) =>
  request('PUT', '/sso/provisioning', {
    enabled: status,
    defaultUserRoleID: roleID,
  });

const fetchSSOOAuthSettings = () => request('GET', '/sso/oauth/settings');

const setSSOOAuth = (googleOAuthEnabled: boolean) =>
  request('PUT', '/sso/oauth/settings', { googleOAuthEnabled });

export const live = {
  refreshAccessToken,
  ssoSignIn,
  ssoCallback,
  ssoOAuthSignIn,
  ssoOAuthCallback,
  fetchSSOStatus,
  ssoSetup,
  enforceSSO,
  provisionUsersSSO,
  fetchSSOOAuthSettings,
  setSSOOAuth,
};

const api = { ...live };
export default api;

export const replaceAPI = (newApi: Record<string, any>) => {
  Object.keys(newApi).forEach((i) => {
    (api as any)[i] = newApi[i];
  });
};
