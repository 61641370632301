import { request } from '@/api/request';

const fetchAssociatedActivityItems = (connectionID: string, associatedID: string) =>
  request('GET', `/activity/associated`, undefined, { connectionID, associatedID });

const fetchConnectionActivityItems = (
  connectionID: string,
  limit: any,
  fromDate: any,
  toDate: any,
  excluding: any,
) =>
  request('GET', `/activity/connection/${connectionID}`, null, {
    limit,
    fromDate,
    toDate,
    excluding,
  });

export const live = {
  fetchAssociatedActivityItems,
  fetchConnectionActivityItems,
};

const api = { ...live };
export default api;

export const replaceAPI = (newApi: Record<string, any>) => {
  Object.keys(newApi).forEach((i) => {
    (api as any)[i] = newApi[i];
  });
};
