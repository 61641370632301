import { request } from '@/api/request';
import type { ProfileType } from '@/types/models/profile';

export type SupplierInfoResponse = {
  id: string;
  name: string;
  companyNumber: string;
  country: string;
  streetAddress: string;
  postcode: string;
  city: string;
  sector: string;
  tradingNames: string[];
  website: string;
  profiles?: ProfileType[];
  connectionProfileType?: ProfileType;
  managed: boolean;
};
const fetchSupplierInfoByOrgID = (orgID: string) =>
  request<SupplierInfoResponse>('GET', `/supplier/${orgID}/info`);

export type ClientInfoResponse = {
  id: string;
  name: string;
  companyNumber: string;
  country: string;
  streetAddress: string;
  postcode: string;
  city: string;
  sector: string;
  tradingNames: string[];
  website: string;
};
const fetchClientInfoByOrgID = (orgID: string) =>
  request<ClientInfoResponse>('GET', `/client/${orgID}/info`);

export type SubEntityInfoResponse = {
  id: string;
  name: string;
  logoUrl: string;
  companyNumber: string;
  country: string;
  streetAddress: string;
  postcode: string;
  city: string;
  sector: string;
  tradingNames: string[];
  website: string;
};
const fetchSubEntityInfoByOrgID = (orgID: string) =>
  request<SubEntityInfoResponse>('GET', `/federatedsubentities/${orgID}/info`);

export type InviteInfoResponse = {
  id: string;
  recipientOrgName: string;
  recipientEmail: string;
  sendOrgID: string;
  senderUserID: string;
  createdAt: string;
  accepted: boolean;
  acceptedAt: string | null;
  acceptedByOrgID: string | null;
};
const fetchInviteInfoByOrgID = (orgID: string) =>
  request<InviteInfoResponse>('GET', `/invites/accepted/${orgID}`);

export type FederatedInviteInfoResponse = {
  id: string;
  recipientOrgName: string;
  recipientEmail: string;
  senderOrgID: string;
  senderUserID: string;
  createdAt: string;
  acceptedAt: string;
  acceptedByOrgID: string;
};
const fetchFederatedInviteInfoByOrgID = (orgID: string) =>
  request<FederatedInviteInfoResponse>('GET', `/federatedinvites/accepted/${orgID}`);

export const live = {
  fetchSupplierInfoByOrgID,
  fetchClientInfoByOrgID,
  fetchSubEntityInfoByOrgID,
  fetchInviteInfoByOrgID,
  fetchFederatedInviteInfoByOrgID,
};

const api = { ...live };
export default api;

export const replaceAPI = (newApi: Record<string, any>) => {
  Object.keys(newApi).forEach((i) => {
    (api as any)[i] = newApi[i];
  });
};
