export default {
  en: {
    dashboardBanner: {
      title: 'External Monitoring',
      comingSoon: 'Coming soon',
      text: "Gain real-time insights on your suppliers' security posture, empowering collaboration and informed decision-making across your entire supply chain",
      cta: 'Register your interest',
      thanks: 'Thanks for your interest',
      toggleAnimation: 'Toggle animation',
    },
    supplierMonitoringInterest: {
      pageTitle: 'External Monitoring',
      title: 'External Monitoring',
      text: 'Gain real-time, actionable insights, allowing you to visualise your external security posture',
      cta: 'Register your interest',
      thanks: 'Thanks for your interest',
    },
    menu: {
      scans: 'Scans',
      assets: 'Assets',
    },
    categories: {
      dnsSecurity: 'DNS Security',
      emailSecurity: 'Email Security',
      webSecurity: 'Web Security',
    },
    categoryDescription: {
      dnsSecurity:
        'These controls ensure your DNS infrastructure is operating efficiently and reliably.',
      emailSecurity: 'These controls verify email legitimacy and build sending reputation.',
      webSecurity:
        'These configurations ensure secure content delivery and prevent malicious code.',
    },
    statusIcon: {
      altText: {
        good: 'Low',
        okay: 'Medium',
        bad: 'High/Critical',
      },
    },
    supplierMonitoring: {
      title: 'External Monitoring',
      beta: 'Beta version: This is an early release and features may change based on feedback.',
      assetStatus: {
        ACTIVE: 'Active',
        DISABLED: 'Inactive',
        UNREACHABLE: 'Unreachable',
        label: 'Status',
      },
      scanSeverity: {
        CRITICAL: 'Critical',
        HIGH: 'High',
        MEDIUM: 'Medium',
        LOW: 'Low',
        label: 'Severity',
      },
      activeAssets: 'Active Assets',
      activeAssetsDescription:
        'Regular security monitoring is running for these assets. Security checks include email authentication and web security configuration.',
      disabledAssets: 'Inactive Assets',
      disabledAssetsDescription:
        'Security monitoring is paused or unavailable for these assets. This could be due to manual pause or connection issues.',
      createdAt: 'Added',
      createdBy: 'Added by',
      lastModifiedAt: 'Updated at',
      lastModifiedBy: 'Updated by',
      editAssetButton: 'Edit asset',
      addAssetButton: 'Add asset',
      addAssetModalTitle: 'Add asset',
      editAssetModalTitle: 'Edit asset',
      assetValue: 'Asset name',
      assetValuePlaceholder: 'example.com',
      assetDescription: 'Description of asset',
      assetDescriptionPlaceholder: 'Production website',
      assetDescriptionHelpText: 'Add any notes that help identify this asset to your clients',
      saveAssetButton: 'Save asset',
      deleteAsset: 'Delete asset',
      deleteAssetSuccess: 'Asset deleted successfully',
      deleteAssetError: 'Error deleting asset',
      editAssetError: 'Error saving asset',
      addAssetError: 'Error adding asset',
      addAssetSuccess: 'Asset added successfully',
      saveAssetSuccess: 'Asset saved successfully',
      rescanAssetError: 'Error triggering asset re-scan',
      assetCreatedBySystem: 'System',
      cancelButton: 'Cancel',
      emptyTitle: 'You haven’t added any assets yet',
      emptySubtitle: 'Add your first asset so we can display ',
      emptyCTA: 'Add an Asset',
      systemStatusUpdateNotAllowed: "Your organisation's website cannot be set to inactive",
      registeredWebsite: 'Registered Website',
    },
    supplierMonitoringScans: {
      emptyTitle: 'You don’t have any scans yet',
      emptySubtitle: 'You will need to add an asset before we can display any scans',
      emptyCTA: 'Add an Asset',
      scanningTitle: 'We are scanning your asset',
      scanningSubtitle: 'Come back in a few minutes to see the results',
      feedbackTitle: 'Give us your feedback',
      feedbackText:
        'We are still building out this feature and would love to hear how you’re finding it.',
      feedbackCTA: 'Send us feedback',
    },
    supplierTab: {
      emptyTitle: 'This supplier has not set up any assets to be monitored',
      emptySubtitle: 'Contact the supplier if you want them to share an asset',
      emptyCTA: 'Start a Discussion',
      assetsTitle: 'Assets',
      assetsSubtitle:
        'These are the assets we are scanning to understand the security position of this supplier from an external monitoring perspective',
      moreDomainsTitle: 'Not seeing an asset you use?',
      moreDomainsSubtitle:
        'Reach out to this supplier if you believe they should add an asset your organisation uses',
      moreDomainsCTA: 'Start a Discussion',
    },
    scanResult: {
      scanDescriptionTitle: 'What does this scan type check for?',
      scanLearnMore: 'Learn more about this scan',
      domains: 'Assets Scanned',
      // is plural and singular
      // depending on number of findings
      associatedControls: 'This scan relates to control: | This scan relates to controls:',
      associatedScans: 'This control relates to scan: | This control relates to scans:',
      scanTitle: {
        SPF: 'Are SPF records protecting the email domain?',
        DKIM: "Is DKIM protecting the domain's emails from tampering?",
        DMARC: "Is DMARC enforcing the domain's email security policies?",
        HSTS: 'Is HSTS enforcing secure connections to your domain?',
        CSP: 'Is the Content Security Policy (CSP) protecting the domain from malicious code?',
        TLS: 'Is TLS configured securely on the domain?',
        SecurityHeaders: 'Are HTTP security headers protecting the domain?',
        DNS: 'Is the DNS configuration secure?',
      },
      scanDescription: {
        SPF: 'SPF (Sender Policy Framework) helps prevent email spoofing by specifying which servers can send email from your domain. Your SPF records need to list all legitimate email sending services while blocking unauthorised senders.',
        DKIM: 'We monitor your DKIM (DomainKeys Identified Mail) configuration to verify your emails are digitally signed and protected against tampering.',
        DMARC:
          'We monitor your DMARC (Domain-based Message Authentication, Reporting, and Conformance) configuration to ensure you have a strong policy that tells receiving servers how to handle suspicious emails from your domain.',
        HSTS: 'We monitor your HSTS (HTTP Strict Transport Security) configuration to ensure browsers only connect to your domain using secure HTTPS connections.',
        CSP: 'We monitor your CSP (Content Security Policy) configuration to ensure your website only loads resources from trusted sources and prevents code injection attacks.',
        TLS: "Transport Layer Security (TLS) creates an encrypted connection between your website and its visitors. We monitor your TLS configuration to ensure it's properly securing data in transit.",
        SecurityHeaders:
          "HTTP security headers tell browsers how to handle your website's content securely. We monitor these headers to ensure they're properly configured to prevent common web attacks.",
        DNS: 'We monitor your DNS (Domain Name System) configuration to ensure your domain is correctly resolving to the right IP address and is protected against DNS spoofing attacks.',
      },
      error: 'Scan unsuccessful',
      unknownAsset: 'Unknown asset',
      tabs: {
        overview: 'Overview',
        notes: 'Private Notes',
        discussions: 'Discussions',
      },
      scanScheduled: 'Asset scan scheduled',
      scan: 'Scan: ',
      expandScan: 'Expand scan information',
      findingsCount: '{n} finding | {n} findings',
      noFindings: 'We scanned this asset and found no issues',
      lastScanned: 'Last scanned',
      scanInProgress: 'Scan in progress:',
      scanInProgressText: 'this may take a few minutes',
      rescan: 'Scan now',
      findings: 'Findings',
      criticality: 'Criticality',
      hasRisks: 'Has risks',
      hasDiscussions: 'Has discussions',
      hasNotes: 'Has notes',
    },
    contextNote: {
      emptyTitle: 'Give context to your clients on these findings',
      emptyCTA: 'Add a note',
      note: 'Note',
      description: 'Add context for your clients about this scan’s findings',
      context: 'Add context for your clients about this scan’s findings',
      remove: 'Remove note',
    },
    clientAssetScanNote: {
      placeholder: 'Write a message...',
      visibleLabel: 'Only visible to {0}',
    },
    onboardingModal: {
      title: 'External Monitoring',
      subHeading: 'Monitor your public domains for free',
      introduction:
        'Risk Ledger now offers monitoring of your public domains, so you and select clients can have a more holistic view of your security posture.',
      setup: "You'll be able to:",
      bullet1:
        'Get visibility on security issues you might not know about and notifications when issues arise',
      bullet2: 'Understand how your domains look to both clients and attackers',
      conclusion: 'This service is {free} and you are in control of the domains that are scanned.',
      free: 'free',
      closeButton: 'See my scans',
    },
    addDomainBanner: {
      title: 'Add your public domains',
      desc: 'We’ve already scanned your registered website, add any others that your clients engage with, or you want to see your security posture on. ',
      button: 'Add another domain',
    },
  },
};
