import { request } from '@/api/request';
import type {
  FilterExpression,
  PaginationRequest,
  SortOption,
  DatasetResponse,
} from '@/libs/search/types';
import { isGroupingFilterExpression } from '@/libs/search/types';
import type { Risk, RiskSettings, RiskLevel, RiskRange } from '../types';

export type OpenRiskVariables = {
  name: string;
  targetOrgID: string;
  associatedType?: string;
  associatedID?: string;
  description: string;
  uploads: string[];
  riskType: string;
  riskMatrix?: {
    axisValues: {
      xValueID?: string | null;
      yValueID?: string | null;
    };
  } | null;
};

const openRisk = (riskData: OpenRiskVariables) => request('POST', '/risks', riskData);

export type RisksResponse = DatasetResponse & {
  risks: Risk[];
  totalRisks: number;
};
const fetchRisks = (
  filter?: FilterExpression,
  sort?: SortOption[],
  page?: PaginationRequest | null,
) =>
  request<RisksResponse>('POST', '/risks/list', {
    // Nested filter needs operands for backend validation to succeed.
    // Send no filter when attempting to send a nested filter with no operands.
    filter:
      filter && isGroupingFilterExpression(filter) && filter?.operands?.length === 0
        ? undefined
        : filter,
    sort,
    page,
  });

const fetchRisk = (number: number | string) => request('GET', `/risks/${number}`);

const updateRisk = (risk: Risk, riskNumber: number) => request('PUT', `/risks/${riskNumber}`, risk);

const closeRisk = (number: number) => request('POST', `/risks/${number}/close`);

const reopenRisk = (number: number) => request('POST', `/risks/${number}/reopen`);

const createRiskNote = (note: any, number: number) =>
  request('POST', `/risks/${number}/notes/create`, note);

const fetchRiskNotes = (number: number) => request('GET', `/risks/${number}/notes`);

const fetchRiskOwners = () => request('GET', `/risks/owners`);

const deleteRisk = (number: number) => request('DELETE', `/risks/${number}`);

const fetchRiskSettings = () => request('GET', '/risks/settings');

const updateRiskSettings = (settings: RiskSettings) => request('PUT', '/risks/settings', settings);

export type RiskLevelsResponse = {
  axes: {
    x: { label: string; axis: RiskLevel[] };
    y: { label: string; axis: RiskLevel[] };
  };
  calculations: {
    [key: string]: number;
  };
  ranges: RiskRange[];
};
const fetchRiskMatrix = () => request<RiskLevelsResponse>('GET', '/risks/matrix');

export type UpdateRiskLabelVariables = {
  id: string;
  axis: string;
  label: string;
  position: number;
}[];

const updateRiskLabel = (riskLabel: UpdateRiskLabelVariables) =>
  request('PUT', '/risks/matrix/axisvalues', riskLabel);

export type CreateRiskLabelVariables = {
  axis: string;
  label: string;
  position: number;
}[];

const createRiskLabel = (riskLabel: CreateRiskLabelVariables) =>
  request('POST', '/risks/matrix/axisvalues', riskLabel);

const deleteRiskLabel = (id: string) => request('DELETE', `/risks/matrix/axisvalues/${id}`);

const fetchRiskMatrixScore = (xAxisValueID: string, yAxisValueID: string) =>
  request('GET', '/risks/matrix/score', null, { xAxisValueID, yAxisValueID });

export const live = {
  openRisk,
  fetchRisks,
  fetchRisk,
  updateRisk,
  closeRisk,
  reopenRisk,
  createRiskNote,
  fetchRiskNotes,
  fetchRiskOwners,
  deleteRisk,
  fetchRiskSettings,
  updateRiskSettings,
  fetchRiskMatrix,
  updateRiskLabel,
  createRiskLabel,
  deleteRiskLabel,
  fetchRiskMatrixScore,
};

const api = { ...live };
export default api;

export const replaceAPI = (newApi: Record<string, any>) => {
  Object.keys(newApi).forEach((i) => {
    (api as any)[i] = newApi[i];
  });
};
