import { AxiosError } from 'axios';
import { type RouteRecordRaw, createRouter, createWebHistory } from 'vue-router';
import config from '@/config/widgets';
import { isAuthenticated, isAuthenticatedByRL, logout } from '@/modules/auth/auth';
import { useMode } from '@/modules/mode/composables';
import { trackView } from '@/libs/analytics';
import useFeatureFlags from '@/composables/feature-flags';
import api from '@/api/health';
import routes from './routes';

const scrollPositions: Record<string, number> = {};

const router = createRouter({
  history: createWebHistory(),
  routes: routes as RouteRecordRaw[],
  scrollBehavior(to, _from, savedPosition) {
    // We check savedPosition to ensure that it's a history navigation
    const newScroll = scrollPositions[to.name as string];
    if (savedPosition && to.name && newScroll !== undefined) {
      const scrollableEl = document.querySelector(`[data-scroll-target="${to.name as string}"]`);
      if (scrollableEl) {
        scrollableEl.scrollTop = newScroll;
      }
    }

    if (savedPosition) return savedPosition;
    if (to.hash) return { el: to.hash };
    return { top: 0 };
  },
});

// Save scroll position - slightly custom approach as we're not using the body
// as our main scrollable element
router.beforeEach((_to, _from, next) => {
  const scrollableEls = Array.from(document.querySelectorAll('[data-scroll-target]'));
  for (const el of scrollableEls) {
    scrollPositions[el.getAttribute('data-scroll-target')!] = el.scrollTop;
  }
  next();
});

// After user logs in (or first page load), check that they can communicate to our backend.
// If the check fails due to network error then this likely means the user
// is having network issues with our API - the auth flow gives more info
// so we log them out (this is the quickest approach, not necessarily the best one).
let checkedNetwork = false;
router.beforeEach(async (_to, _from, next) => {
  const authenticated = await isAuthenticated();
  if (!authenticated) return next();

  // only do this check once, so we don't fetch API health on every route change
  if (checkedNetwork) return next();

  try {
    await api.fetchApiHealth();
    checkedNetwork = true;
  } catch (e) {
    logError(e);
    if (e instanceof AxiosError && e.code === 'ERR_NETWORK') {
      await logout();
      window.location.href = '/';
      return next(false);
    }
  }
  next();
});

// On every route change (and first route), check to see we have feature flags set
// for the appropriate context (i.e. authenticated flags with user/org overrides vs public flags without overrides).
router.beforeEach(async (_to, _from, next) => {
  const authenticated = await isAuthenticatedByRL();
  try {
    await useFeatureFlags().setFlags(authenticated);
  } catch (e) {
    logError(e);
  }
  next();
});

router.beforeEach((to, _from, next) => {
  const { setMode } = useMode();
  if (typeof to.meta.mode === 'string') {
    setMode(to.meta.mode);
  }
  next();
});

router.beforeEach((to, _from, next) => {
  if (!to.matched.some((record) => record.meta.unsafeRefresh)) {
    fetch('/hash.json')
      .then((res) => res.json())
      .then((res) => {
        if (res.hash !== config.buildSHA) {
          window.location.href = to.fullPath;
        }
      });
  }
  next();
});

// @see: https://github.com/vitejs/vite/issues/11804#issuecomment-1406182566
// if the user has an older cached chunk, refresh the page to get the latest version
router.onError((error, to) => {
  if (error.message.includes('Failed to fetch dynamically imported module')) {
    window.location = to.fullPath as string & Location;
  }
});

router.afterEach((to, from) => {
  trackView(to, from);
});

export default router;
