import { request } from '@/api/request';

const fetchProfileSharingLinks = () => request('GET', '/profilesharing/links');

const createProfileSharingLink = ({
  name,
  daysToExpiry,
  selectedDomains,
  autoConnectEnabled,
}: any) =>
  request('POST', '/profilesharing/links', {
    name,
    daysToExpiry,
    selectedDomains,
    autoConnectEnabled,
  });

const sendProfileSharingLink = (id: string, recipientEmail: string, message: string) =>
  request('POST', `/profilesharing/links/${id}/send`, { recipientEmail, message });

const deactivateProfileSharingLink = (id: string) =>
  request('POST', `/profilesharing/links/${id}/deactivate`);

const updateProfileSharingLink = (id: string, { name, selectedDomains, autoConnectEnabled }: any) =>
  request('PUT', `/profilesharing/links/${id}`, { name, selectedDomains, autoConnectEnabled });

export const live = {
  fetchProfileSharingLinks,
  createProfileSharingLink,
  sendProfileSharingLink,
  deactivateProfileSharingLink,
  updateProfileSharingLink,
};

const api = { ...live };
export default api;

export const replaceAPI = (newApi: Record<string, any>) => {
  Object.keys(newApi).forEach((i) => {
    (api as any)[i] = newApi[i];
  });
};
