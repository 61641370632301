import assessment from './assessment';
import reassessment from './reassessment';

export const live = {
  ...assessment,
  ...reassessment,
};

const api = { ...live };
export default api;

export const replaceAPI = (newApi: Record<string, any>) => {
  Object.keys(newApi).forEach((i) => {
    (api as any)[i] = newApi[i];
  });
};
