import { request } from '@/api/request';
import type { Control, ScanTypeControlPair } from '@/types/models/control';
import type { Domain } from '@/types/models/domain';
import type {
  CombinedFrameworkConfig,
  FrameworkConfig,
  FrameworkLevel,
} from '@/types/models/framework-config';

export type GetFrameworkResponse = {
  controls: Control[];
  domains: Domain[];
  levels: FrameworkLevel[];
  scanTypeControlPairs: ScanTypeControlPair[];
};

const getFramework = () => request<GetFrameworkResponse>('GET', '/framework');

const getFrameworkDefaultConfig = () =>
  request<FrameworkConfig>('GET', '/framework/config/default');

const setFrameworkDefaultConfig = (config: FrameworkConfig) =>
  request<FrameworkConfig>('PUT', '/framework/config/default', config);

const getFrameworkConfig = (connectionID: string) =>
  request<FrameworkConfig>('GET', `/framework/config/connection/${connectionID}`);

const setFrameworkConfig = (connectionID: string, config: FrameworkConfig) =>
  request<FrameworkConfig>('POST', `/framework/config/connection/${connectionID}`, config);

const getCombinedFrameworkConfig = () =>
  request<CombinedFrameworkConfig>('GET', '/framework/config/supplier');

const api = {
  getFramework,
  getFrameworkDefaultConfig,
  setFrameworkDefaultConfig,
  getFrameworkConfig,
  setFrameworkConfig,
  getCombinedFrameworkConfig,
};
export default api;

export const replaceAPI = (newApi: Record<string, any>) => {
  Object.keys(newApi).forEach((i) => {
    (api as any)[i] = newApi[i];
  });
};
