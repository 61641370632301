import api from '../api';

const getAssessmentStatus = async ({ commit, dispatch }) => {
  const res = await api.getAssessmentStatus();
  commit('SET_ASSESSMENT_STATUS', res.data);
  await dispatch('getReassessmentStatus');
};

const changeScopingAnswer = async ({ commit }, { domainID, answer, notes }) => {
  const res = await api.changeScopingAnswer(domainID, answer, notes);
  commit('CHANGE_SCOPING_ANSWER', res.data);
};

const getScopingAnswers = async ({ commit }) => {
  const res = await api.getScopingAnswers();
  commit('SET_SCOPING_ANSWERS', res.data);
};

const getControlBookmarks = async ({ commit }) => {
  const res = await api.getControlBookmarks();
  commit('SET_CONTROL_BOOKMARKS', res.data);
};

const bookmarkControl = async ({ commit }, { controlID }) => {
  const res = await api.bookmarkControl(controlID);
  commit('SET_CONTROL_BOOKMARK', res.data);
};

const unbookmarkControl = async ({ commit }, { controlID }) => {
  const res = await api.unbookmarkControl(controlID);
  commit('SET_CONTROL_BOOKMARK', res.data);
};

const answerControl = async ({ dispatch }, { controlID, answer }) => {
  await api.answerControl(controlID, answer);
  // Every time a control is answered we update the assessment progress
  // A delay is used in order for the answer changed event to be handled in the BE.
  setTimeout(() => {
    dispatch('getAssessmentStatus');
  }, 200);
};

const confirmControlUpdate = async (_store, controlID) => {
  await api.confirmControlUpdate(controlID);
};

const getReassessmentStatus = async ({ commit }) => {
  const res = await api.getReassessmentStatus();
  commit('SET_REASSESSMENT_STATUS', res.data);
};

const submitAssessment = async ({ commit }) => {
  const res = await api.submitAssessment();
  commit('SET_ASSESSMENT_STATUS', res.data);
};

const startReassessment = async ({ commit }) => {
  const res = await api.startReassessment();
  commit('START_REASSESSMENT', res.data);
};

const confirmDomain = async ({ commit, dispatch }, domainID) => {
  const res = await api.confirmDomain(domainID);
  commit('SET_REASSESSMENT_CONTROLS', res.data.reassessmentStatus);
  dispatch('getAssessmentStatus');
};

export const actions = {
  getAssessmentStatus,
  changeScopingAnswer,
  getScopingAnswers,
  answerControl,
  submitAssessment,
  startReassessment,
  getReassessmentStatus,
  confirmDomain,
  confirmControlUpdate,
  getControlBookmarks,
  bookmarkControl,
  unbookmarkControl,
};
