const state = {
  api_error: null,
  loading: {},
};

const mutations = {
  SET_API_ERROR(state: any, error: any) {
    state.api_error = error;
  },
  SET_API_LOADING(state: any, { flag, loading }: any) {
    if (loading) {
      state.loading[flag] = true;
    } else {
      delete state.loading[flag];
    }
  },
};

export default { state, mutations };
